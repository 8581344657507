<template>
  <div id="app">
    <!-- <div class="logo">
        <img src="./assets/images/bentley_logo_a-c1@2x.png"  alt="" />
    </div> -->
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style src="normalize.css"></style>
<style lang="scss">
@font-face {
    font-family: 'Bentley';
    src: url(~@/assets/font/Bentley-Regular.otf);
}
@font-face {
    font-family: 'simhei';
    src: url(~@/assets/font/simhei.ttf);
}
#app {
  font-family: 'Bentley', 'simhei', RegularEng, Helvetica, 'Avenir', Arial, sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  text-align: left;
  /*margin-top: 60px;*/
  background: #efeef3;
  min-height: 100vh;
  .logo {
    width: 375px;
    // height: 62px;
    height: 80px;
    // background-image: url('~@/assets/images/logo.png');
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    // background-size: auto 40px;
    // background-position: 10px center;
    background-repeat: no-repeat;
    img {
      width: 84px;
      height: 42px;
      margin: 20px 20px;
    }
  }
}
html,
body {
  font-family: 'Bentley', 'simhei', RegularEng, Helvetica, 'Avenir', Arial, sans-serif !important;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
input {
  border: 0 !important;
  outline: none !important;
}
</style>

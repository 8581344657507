import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 设置默认时区为 Asia/Shanghai
import moment from 'moment-timezone'
import 'vant/lib/index.less';
import VConsole from 'vconsole';
// const vConsole = new VConsole({maxLogNumber: 1000});


window.vm = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

moment.tz.setDefault('Asia/Shanghai')

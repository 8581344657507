export default [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    component: () => import('@/views/login2'),
    name: 'Login',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/afterForm/',
    component: () => import('@/views/afterForm'),
    name: 'AfterForm',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/form/',
    component: () => import('@/views/form'),
    name: 'Form',
    meta: {
      title: '線上學習平台',
      needLogin: true
    }
  },
  {
    path: '/gm-first/',
    component: () => import('@/views/gm-first'),
    name: 'GMFirst',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/gm-list/',
    component: () => import('@/views/gm-list'),
    name: 'GMList',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/finish/',
    component: () => import('@/views/finish'),
    name: 'Finish',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/user-agreement',
    component: () => import('@/views/user-agreement'),
    name: 'UserAgreement',
    meta: {
      title: '個人信息保護聲明'
    }
  },
  {
    path: '/enrollment/',
    component: () => import('@/views/enrollment'),
    name: 'Enrollment',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/course-detail/:courseId',
    component: () => import('@/views/course-detail'),
    name: 'CourseDetail',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/course-detail/:courseId',
    component: () => import('@/views/course-detail-xx'),
    name: 'XxCourseDetail',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/course-detail-c/:courseId',
    component: () => import('@/views/course-detail-c'),
    name: 'CourseDetailCancel',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/course-detail-c/:courseId',
    component: () => import('@/views/course-detail-c-xx'),
    name: 'XxCourseDetailCancel',
    meta: {
      title: '線上學習平台'
    }
  },
  {
    path: '/traininglist/',
    component: () => import('@/views/training-list'),
    name: 'TrainingList',
    meta: {
      title: '線上學習平台',
    }
  },
  {
    path: '/video-content/:videoId',
    component: () => import('@/views/video-content'),
    name: 'VideoContent',
    meta: {
      title: '線上學習平台',
    }
  },
  {
    path: '/appointment/',
    component: () => import('@/views/appointment'),
    name: 'Appointment',
    meta: {
      title: '賓利經銷商認證評估中心'
    }
  }
]
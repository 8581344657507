import axios from 'axios'

import urlTemplate from 'url-template'
import router from '../../router'

export class Charon {
  static axios

  static interceptors = axios.interceptors

  static create = axios.create

  static Cancel = axios.Cancel

  static CancelToken = axios.CancelToken

  static isCancel = axios.isCancel

  static all = axios.all

  static spread = axios.spread

  static defaults = axios.defaults

  Charon

  url

  method

  config

  constructor(url, method, config = {}) {
    this.url = url
    this.method = (method && method.toLowerCase()) || 'get'
    this.config = config
  }

  async fetch(mixedParamsData = {}, config = {}) {
    // merge config
    const mergedConfig = {...this.config, ...config}
    const {perferNativeResponse = false, urlData = {}, data = {}, params = {}, ...requestConfig} = mergedConfig

    // 请求数据构造
    // 无 body 请求时，数据作为 params 传入
    // 有 body 请求时，数据作为 data 传入
    let requestContent
    if (this.method === 'get' || this.method === 'delete' || this.method === 'head') {
      requestContent = {params: {...mixedParamsData, ...params}}
    } else {
      const postData = {...mixedParamsData, ...data}
      requestContent = {params, data: postData}
    }

    // 构造 URL
    const url = urlTemplate.parse(this.url).expand(urlData)

    // 请求数据
    const response = await axios.request({
      url,
      method: this.method,
      ...requestConfig,
      ...requestContent
    })
    // console.dir(response)
    if (response.data.code == 401) {
      router.replace({name: "Login"})
    }
    // 按配置返回
    if (perferNativeResponse) return response
    return response.data
  }
}

/* eslint-disable no-shadow */
const CharonResource = (url, method, config = {}) => {
  const {...charonConfig} = config
  const charon = new Charon(url, method, charonConfig)
  return async(data, config = {}) => {
    return await charon.fetch(data, config)
  }
}

export {axios, CharonResource}
